<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">


        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-25 mx-auto"
        >
          <b-row class="mb-3" align-h="between">
            <div class="text-left"> <b-img :src="odesa_logo" height="60px" alt="Responsive image"></b-img></div>
            <div class="text-right list-unstyled mb-1">
              <locale />
            </div>
          </b-row>

          <b-card-title
              class="mb-1 font-weight-bold text-center"
              title-tag="h2"
          >
            {{ $t('login_page.card_title') }}
          </b-card-title>
          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text"></div>
          </div>
          <b-card-text class="mb-2">
            {{ $t('login_page.card_text') }}
          </b-card-text>

          <b-alert
              variant="primary"
              show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | 123456Aa*  <feather-icon color="red" @click="demo_login('admin')" icon="LogInIcon"/></small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com |123456Aa* <feather-icon color="red" @click="demo_login('client')" icon="LogInIcon"/>
                </small>
              </p>
            </div>
            <feather-icon
                v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
                icon="HelpCircleIcon"
                size="18"
                class="position-absolute"
                style="top: 10; right: 10;"
            />
          </b-alert>

          <!-- form -->
          <validation-observer
              ref="loginForm"
              #default="{invalid}"
          >
            <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                  :label="$t('login_page.lbl_email')"
                  label-for="login-email"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                >
                  <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="alikaplan@odesaenergy.com"
                  />
                  <small v-if="errors[0]" class="text-danger">{{ $t('core.required') }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('login_page.lbl_password') }}</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>{{ $t('login_page.forgot_password') }}</small>
                  </b-link>
                </div>
                <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        :placeholder="$t('login_page.password_placeholder')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors[0]" class="text-danger">{{ $t('core.required') }}</small>
                </validation-provider>
              </b-form-group>



              <!-- submit buttons -->
              <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
              >
                {{ $t('login_page.btn_login') }}
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>

      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BCard,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    Locale,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '123456Aa*',
      userEmail: 'admin@demo.com',
      odesa_logo: require('@/assets/images/logo/odesa2.png'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.loginUser({
            email: this.userEmail,
            password: this.password,
          })
              .then(response => {
                const { userData } = response.data
                useJwt.setToken(response.data.access)
                useJwt.setRefreshToken(response.data.refresh)
                localStorage.setItem('userData', JSON.stringify(userData))
                this.$ability.update(userData.ability)

                this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Hoşgeldin ${userData.name} ${userData.last_name}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                        },
                      })
                    })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Hata',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: 'Eposta ya da Parola hatalı!',
                  },
                })
              })
        }
      })
    },
    demo_login(user) {
      if (user == 'admin') {
        this.password = "123456Aa*"
        this.userEmail = "admin@demo.com"
      }else if (user == 'client') {
        this.password = "123456Aa*"
        this.userEmail = "client@demo.com"
      }
      this.login()
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
